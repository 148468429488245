const Mock = require('mockjs');
export default [
    {
        url: '/focus/list',
        type: 'get',
        response: () => {
            return {
                code: 20000,
                data: [
                    {
                        id: 1,
                        title: '1',
                        img: 'https://s4.ax1x.com/2022/02/22/HzzyKe.jpg'
                    },
                    {
                        id: 2,
                        title: '2',
                        img: 'https://s4.ax1x.com/2022/02/22/HzzDgO.jpg'
                    },
                    {
                        id: 3,
                        title: '3',
                        img: 'https://s4.ax1x.com/2022/02/22/HzzrvD.jpg'
                    }
                ]
            }
        }
    }
]