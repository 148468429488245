import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: { title: '官网首页'}
    },
    {
        path: '/category/product',
        name: 'product',
        component: () => import('../views/Product.vue'),
        meta: { title: '企业产品'}
    },
    {
        path: '/category/course',
        name: 'course',
        component: () => import('../views/Course.vue'),
        meta: { title: '发展历程'}
    },
    {
        path: '/category/strategy',
        name: 'strategy',
        component: () => import('../views/Strategy.vue'),
        meta: { title: '企业愿景'}
    },
    {
        path: '/culture',
        name: 'culture',
        component: () => import('../views/Culture.vue'),
        meta: { title: '企业文化'}
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue'),
        meta: { title: '关于我们'}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    let title = '好生利·红点香赵州饼'
    if (to.meta.params){
        title = `${to.meta.title}:${to.params[to.meta.params] || ''} - ${title}`
    }else {
        title = `${to.meta.title} - ${title}`
    }
    document.title = title
    if (to.path !== from.path) {
        store.dispatch('setLoading', true);
    }
    next();
})
router.afterEach((to, from) => {
    // 最多延迟 关闭 loading
    setTimeout(() => {
        store.dispatch('setLoading', false);
    }, 1500)
})
export default router
